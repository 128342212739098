html {
  font-size: 12px;
}

/* When the screen (viewport) width is less than 768px, adjust the font size */
@media (max-width: 768px) {
  html {
    font-size: 10px; /* Adjust root font size */
  }
}

/* When the screen width is less than 480px, adjust the font size even more */
@media (max-width: 480px) {
  html {
    font-size: 8px; /* Adjust root font size */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
